// console.log('Processing config.js, looking for element <body>')
// console.log(document.getElementsByTagName('body'))
export const getConfig = () => {
    // TODO debug here - reload on local with django cms admin breaks JS
    if (document.getElementsByTagName('body').length > 0)
        return document.getElementsByTagName('body')[0].dataset

}

export const apiUrl = getConfig()?.['publicApiUrl']
export const staticUrl = getConfig()?.['publicStaticUrl']
export const debug = getConfig()?.['debug'] === 'True'

export const isAdmin = !!window.isAdmin // converts to bool

export const sentryConfig = {
    environment: getConfig() && getConfig()['sentryEnvironment'],
    dsn: 'https://64ad78365fb3f30144ab34182637a77d@o1001156.ingest.sentry.io/4506581831974912',
    traceSampleRate: 0.1,
    sendDefaultPii: true,
}

export const clarityConfig = {
    projectId: 'jn8ln6mca7',
    upload: 'https://m.clarity.ms/collect',
    track: true,
    content: true,
}

export const currentLanguage = document.getElementsByTagName('html')[0].lang
export const currentLocale = (() => {
    switch (currentLanguage) {
    case 'fr':
        return 'fr-FR'
    case 'en':
        return 'en-US'
    case 'pt-br':
        return 'pt-BR'
    case 'es':
        return 'es-ES'
    case 'it':
        return 'it-IT'
    case 'de':
        return 'de-DE'
    case 'cs':
    default:
        return 'cs-CZ'
    }
})()


export const privacyPolicyScopes = {
    // key is used as id for html elements and storage key acquaring
    analytics: {
        title: 'PRIVACY_ANALYTICS_HEADLINE', // translation key
        description: 'PRIVACY_ANALYTICS_DESCRIPTION', // translation key
        storageKey: 'ANALYTICS' // key in object which is saved to localstorage
    },
    clarity: {
        title: 'PRIVACY_CLARITY_HEADLINE',
        description: 'PRIVACY_CLARITY_DESCRIPTION',
        storageKey: 'CLARITY'
    },
    sentry: {
        title: 'PRIVACY_SENTRY_HEADLINE',
        description: 'PRIVACY_SENTRY_DESCRIPTION',
        storageKey: 'SENTRY'
    }
}


export const CURRENCY_CONFIG = {
    // TODO get from django
    currencies: ['CZK', 'EUR', 'GBP', 'USD'],
    decimalPlaces: 2,
    formatString: '{INTEGER}.{FRACTION}&nbsp;{CURRENCY}',
    groupSize: 3,
    groupDelimeter: '&nbsp;',
    customDecimalPlaces: {
        'CZK': 0
    },
    customFormatString: {
        'en': {
            default: '{CURRENCY}{INTEGER}.{FRACTION}',
            'CZK': '{CURRENCY}&nbsp;{INTEGER}.{FRACTION}',
        },
        'fr': '{INTEGER},{FRACTION}&nbsp;{CURRENCY}',
        'cs': '{INTEGER},{FRACTION}&nbsp;{CURRENCY}',
    },
    customGroupSize: {
        // 'en': 3
    },
    customGroupDelimeter: {
        'en': ','
    },

}